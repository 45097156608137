<template>
  <div class="container">
    <h4 class="text-center my-4">Публикация курсов в Moodle</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="row my-4">
        <div class="col-md-4">
          <Dropdown v-model="departmentId" :options="departments" optionLabel="name_ru" optionValue="id"
                    placeholder="Выберите кафедру"
                    class="w100"/>
        </div>

        <div class="col-md-4">
          <select class="form-control form-select" name="academic_year" v-model="academicYearId">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...educationCurriculum_form.academicYear]"
                :value="item.id"
                :key="index">{{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <PrimePreloader v-if="loadingDisciplines"/>
      <div v-else>
        <div v-if="departmentId&&disciplinesWithCourses.length">

          <DataTable :value="disciplinesWithCourses" showGridlines stripedRows responsiveLayout="scroll"
                     :paginator="true" :rows="10"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[10,20,50,100,500]"
                     currentPageReportTemplate="Показано {first} по {last} из {totalRecords}"
                     filterDisplay="menu"
                     :globalFilterFields="['education_discipline_name']"
                     v-model:filters="filters">

            <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск по дисциплине"/>
            </span>
              </div>
            </template>

            <Column header="№" :style="{width: '60px'}">
              <template #body="{index}">
                {{index+1}}
              </template>
            </Column>
            <Column header="Дисциплина">
              <template #body="{data}">
                <h5>
                  {{ data.education_discipline_name }}
                </h5>

                <div v-for="(combinations, combinationsKey) in data.combinations" :key="'ck'+combinationsKey"
                     class="border rounded-3 p-2 row my-2">
                  <div class="col-md-6">
                    <div v-for="(combination, combinationIndex) in combinations" :key="'ci'+combinationIndex">
                      <span class="text-secondary me-1">{{combination.education_type}}</span>
                      <span v-if="combination.lastname">
                      {{combination.lastname}} {{combination.firstname}}
                    </span>
                      <span v-else>Вакансия</span>
                    </div>
                  </div>
                  <div class="col-md-2 text-end">
                    <Button icon="pi pi-list" label="Студенты" class="p-button-rounded p-button-info"
                            :loading="loadingStudents[combinationsKey]"
                            @click="openStudents(combinationsKey, data.education_discipline_name, combinations[0].mdl_course_id)"
                    />
                  </div>
                  <div class="col-md-4 text-end">
                    <div v-if="combinations[0]?.mdl_course_id">
                      Курс создан
                    </div>
                    <div v-else-if="!decryptCheckRole('departmentHead')">
                      <Button icon="pi pi-send" label="Опубликовать в Moodle" class="p-button-rounded"
                              :disabled="combinations.some(i=>!i.can_create_course)"
                              :loading="loadingPublishToMoodle[combinationsKey]"
                              @click="publishToMoodle(combinationsKey)"/>
                    </div>
                  </div>
                </div>
              </template>

            </Column>
            <Column header="Кредит" field="credit"></Column>
            <Column field="semester" header="Сем.">
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="semesters" placeholder="Любой"
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span v-if="slotProps.value">{{slotProps.value}}</span>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                  <template #option="slotProps">
                    <span>{{slotProps.option}}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
          </DataTable>
        </div>

        <div v-else-if="departmentId">Список дисциплин пуст</div>


        <!-- students -->
        <Dialog :header="`Привязанные студентов - ${education_discipline_name}`" :visible="displayStudents"
                :closable="false" :modal="true"
                :style="{width: '100%', maxWidth: '600px'}">
          <div class="my-3">
            <div v-for="(ppsLoad, ppsLoadIndex) in students" :key="'ppsLoadIndex'+ppsLoadIndex"
                 class="mb-5 border p-2 rounded-3">
              <h5>
                {{ppsLoad.type}} Количество: {{ppsLoad.students_count}} (Привязано {{ppsLoad.bindCount}})
              </h5>

              <div class="mb-3">{{ppsLoad.pps_lastname}} {{ppsLoad.pps_firstname}}
                (U{{ppsLoad.pps_teaching_load_id}})
              </div>

              <div v-for="(ep, epKey) in ppsLoad.eps" :key="'epk'+epKey" class="mb-3">
                <div class="text-center fw-bold">{{ep.name}} ({{ep.count}}/{{ep.students.length}})</div>

                <div v-for="(s, sId) in ep.students" :key="'sId'+sId" class="my-2 ms-3">
                  {{sId+1}}. {{s.last_name}} {{s.first_name}} {{s.middle_name}}

                  <div v-if="+s.status===2" class="mt-1 mb-2">
                    <Button label="Удалить весь выбор"
                            class="p-button-outlined p-button-danger p-button-sm p-button-rounded"
                            @click="deleteStudentChoose(s.student_id)"/>
                  </div>
                  <div v-else-if="+s.can_add===1&&!!mdlCourseId" class="mt-1 mb-2">
                    <Button label="Добавить на курс"
                            class="p-button-sm p-button-rounded"
                            :loading="loadingAddToCourse[s.student_id]"
                            @click="addToCourse(s.student_id)"/>
                  </div>


                </div>
              </div>


            </div>

          </div>
          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" @click="closeStudents" class="p-button-text"/>
          </template>
        </Dialog>
        <!-- end disciplines -->

      </div>

    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api';
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: 'PublishCoursesToMoodle',
    data() {
      return {
        loading: true,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'semester': {value: null, matchMode: FilterMatchMode.EQUALS},
        },
        semesters: [1, 2, 3, 4, 5, 6, 7, 8],
        loadingPublishToMoodle: {},

        departmentId: this.$route.query.department_id || 0,
        academicYearId: this.$route.query.academic_year_id || 0,
        loadingDisciplines: false,

        displayStudents: false,
        loadingStudents: {},

        students: [],
        union_course_id: 0,
        mdlCourseId: 0,
        education_discipline_name: 0,
        loadingAddToCourse: {}
      }
    },
    computed: {
      ...mapState('combinedTeachingLoad', ['disciplinesWithCourses']),
      ...mapState('vacancy', ['departments']),
      ...mapState('educationCurriculum', ['educationCurriculum_form']),
    },
    watch: {
      async departmentId() {
        await this.getDisciplines()
      },
      async academicYearId() {
        await this.getDisciplines()
      }
    },
    methods: {
      decryptCheckRole,
      ...mapActions('combinedTeachingLoad', ['GET_DISCIPLINES_WITH_COURSES', 'PUBLISH_TO_MOODLE',
        'GET_STUDENTS_BY_UNION_COURSE_ID',
        'DELETE_SCHEDULE_CHOOSE_BY_STUDENT',
        'ADD_STUDENT_TO_COURSE'
      ]),
      ...mapActions('vacancy', ['GET_DEPARTMENTS']),
      ...mapActions('educationCurriculum', ['GET_SP_ACADEMIC_YEAR']),
      async publishToMoodle(combinationsKey) {
        this.loadingPublishToMoodle[combinationsKey] = true
        const res = await this.PUBLISH_TO_MOODLE({union_course_id: combinationsKey})
        if (res) {
          await this.GET_DISCIPLINES_WITH_COURSES({departmentId: this.departmentId, academicYearId: this.academicYearId})
          this.$message({title: 'Курс успешно создан'})
        }
        this.loadingPublishToMoodle[combinationsKey] = false
      },
      async getDisciplines() {
        this.loadingDisciplines = true
        // &academic_year_id=${this.academicYearId}
        await this.$router.push(`${this.$route.path}?department_id=${this.departmentId}&academic_year_id=${this.academicYearId}`)
        await this.GET_DISCIPLINES_WITH_COURSES({departmentId: this.departmentId, academicYearId: this.academicYearId})

        this.loadingDisciplines = false
      },

      async openStudents(union_course_id, education_discipline_name, mdlCourseId) {
        this.loadingStudents[union_course_id] = true
        this.union_course_id = union_course_id
        this.mdlCourseId = mdlCourseId
        this.education_discipline_name = education_discipline_name
        this.students = await this.GET_STUDENTS_BY_UNION_COURSE_ID(union_course_id)

        this.loadingStudents[union_course_id] = false
        this.displayStudents = true
      },
      closeStudents() {
        this.displayStudents = false
      },
      async deleteStudentChoose(student_id) {
        const res = await this.DELETE_SCHEDULE_CHOOSE_BY_STUDENT(student_id)
        if (res) {
          this.students = await this.GET_STUDENTS_BY_UNION_COURSE_ID(this.union_course_id)
          this.$message({title: 'Успешно удалено'})
        }

      },
      async addToCourse(student_id) {
        this.loadingAddToCourse[student_id] = true
        const res = await this.ADD_STUDENT_TO_COURSE({
          student_id,
          union_course_id: this.union_course_id
        })

        if (res) {
          this.$message({title: 'Студент успешно добавлен'})
          this.students = await this.GET_STUDENTS_BY_UNION_COURSE_ID(this.union_course_id)
        }

        this.loadingAddToCourse[student_id] = false
      },
    },
    async mounted() {
      await this.GET_SP_ACADEMIC_YEAR()
      await this.GET_DEPARTMENTS()

      if (this.departmentId || this.academicYearId) {
        await this.getDisciplines()
      }


      this.loading = false
    },

  }

</script>

<style scoped>
  .w100 {
    width: 100%;
  }
</style>